import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Container,
    CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, Divider,
    FormControlLabel, Grid, Link,
    TextField,
    Typography
} from "@mui/material";
import {ReactComponent as Logo} from "../../logo.svg";
import {useState} from "react";


const SignIn = () => {
    const [bookDemoOpen, setBookDemoOpen] = useState(false);
    const [bookDemoEmail, setBookDemoEmail] = useState("");
    const [loginError, setLoginError] = useState(null);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setEmail("");
        setPassword("");
        setLoginError("User/Password not found");
    };

    const handleBookDemoClick = () => {
        setBookDemoOpen(false);
        fetch('https://api-landing.frazr.io/api/v1/lead', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({email: bookDemoEmail, token: "token"}),
        })
            .then(() => setBookDemoEmail(''))

    }

    return (
        <Container component="signin" maxWidth="xs">
            <CssBaseline/>
            <Box sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: 'white',
                p: 3,
                borderRadius: '10px'
            }}>
                <Box alignSelf={"flex-start"} sx={{m: 1, height: 50}}>
                    <Logo height={100}/>
                </Box>
                <Typography component="h1" variant="h5" sx={{mt: 2}}>
                    Sign In
                </Typography>
                <Typography color={"red"} display={loginError !== null ? "flex" : "none"} sx={{mt: 1, mb: 1}}>
                    {loginError}
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{mt: 1}} noValidate>
                    <TextField fullWidth margin={"normal"} required id={"email"} label={"Email Address"} name={"email"}
                               autoComplete={"email"} autoFocus onChange={e => setEmail(e.target.value)} value={email}
                               error={loginError !== null}/>
                    <TextField fullWidth required margin={"normal"} label={"Password"} type={"password"}
                               name={"password"} autoComplete={"password"} id={"password"} autoFocus
                               onChange={e => setPassword(e.target.value)} value={password}
                               error={loginError !== null}/>
                    <FormControlLabel control={<Checkbox value={"remember"} color={"primary"}/>}
                                      label={"Remember me"}/>
                    <Button fullWidth type={"submit"} variant={"contained"} sx={{mt: 3, mb: 3}}>Sign In</Button>
                    <Divider/>
                    <Typography sx={{mt: 3}}>
                        Don't have an account?
                    </Typography>
                    <Button sx={{mt: 1, mb: 2}} fullWidth variant="contained" onClick={() => setBookDemoOpen(true)}>
                        Book a demo
                    </Button>
                </Box>
            </Box>

            <Dialog open={bookDemoOpen} onClose={() => setBookDemoOpen(false)}>
                <DialogTitle>Book A Demo</DialogTitle>
                <DialogContent>
                    <Typography>
                        Interested in our solution? Book a demo!
                    </Typography>
                    <TextField fullWidth margin={"normal"} required id={"email"} label={"Email Address"} name={"email"}
                               autoComplete={"email"} autoFocus onChange={e => setBookDemoEmail(e.target.value)}
                               value={bookDemoEmail}/>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleBookDemoClick}>Book A Demo</Button>
                </DialogActions>
            </Dialog>

        </Container>
    )
}

export default SignIn;