import './App.css';
import styled from "styled-components";
import {createTheme, GlobalStyles, ThemeProvider} from "@mui/material";
import SignIn from "./components/sign-in/SignIn";
import {Global, css} from "@emotion/react";
import TextGyrHeroesBold from "./fonts/texgyreheros-bold.otf"
import TextGyrHeroesRegular from "./fonts/texgyreheros-regular.otf"

const MainContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
`;

const theme = createTheme({
    palette: {
        primary: {
            main: '#610066'
        },
        text: {
            primary: '#610066',
        }
    },
    typography: {
        fontFamily: ["TeX Gyre Heros"].join(','),
        allVariants: {
            fontFamily: 'TeX Gyre Heros'
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                    font-family: TeX Gyre Heros;
                    font-weight: 600;
                    src: url(${TextGyrHeroesBold}) format("truetype")
                }
                
                @font-face {
                    font-family: TeX Gyre Heros;
                    font-style: normal;
                    src: url(${TextGyrHeroesRegular}) format("truetype")
                }
            `
        }
    }
});

const App = () => {
    return (
        <MainContainer className={"App"}>
            <Global styles={css`body {
                background-size: cover
            }`}/>
            <ThemeProvider theme={theme}>
                <SignIn/>
            </ThemeProvider>
        </MainContainer>
    );
}

export default App;
